var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"no-results-text":"Não há registro.","no-data-text":"Não há registro.","loading-text":"Carregando atendimentos...","loading":_vm.loadingQueue,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","disable-sort":"","page":_vm.page,"items":_vm.supervisorQueue,"item-key":"create_date","headers":[
      {
        text: 'Ticket',
        value: 'ticket',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'state',
        sortable: false,
      },
      {
        text: 'Solicitante',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Duração',
        value: 'service_time',
        sortable: false,
      },
      {
        text: 'Agente',
        value: 'agent',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ]},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:`item.state`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.gFunc.queueStatus(item.state))+" ")])],1)]}},{key:`item.name`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(_vm.gFunc.mobileMask(item.name))+" ")])]}},{key:`item.service_time`,fn:function({ item, index }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(item.agent ? _vm.diffs[index] : "")+" ")])]}},{key:`item.agent`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(item.agent.name ? item.agent.name.toString() : "")+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.supervisorMenu),function(menu,i){return _c('div',{key:i},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setWsAction(menu.action, _vm.userTeamId, item.id, item.team.id, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1),(
                  _vm.supervisorMenu.length > 1 && i < _vm.supervisorMenu.length - 1
                )?_c('v-divider'):_vm._e()],1)}),0)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.showAgentDialog),callback:function ($$v) {_vm.showAgentDialog=$$v},expression:"showAgentDialog"}},[_c('div',{staticStyle:{"padding":"15px","background-color":"#ffffff"}},[_c('h3',[_vm._v("Selecione um agente")])]),_c('v-text-field',{staticStyle:{"background-color":"#ffffff","border-radius":"0"},attrs:{"solo":"","label":"Buscar por nome","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.currentTicketTeamId)?_c('v-data-table',{style:({ borderRadius: '0' }),attrs:{"headers":_vm.headers,"items":_vm.filteredAgents,"items-per-page":5},scopedSlots:_vm._u([{key:"item.agent.name",fn:function({ item }){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.assignServiceToAgent(
              item.agent,
              _vm.currentTicketId,
              item.agent.team.id
            )}}},[_vm._v(" "+_vm._s(item.agent.name)+" ")])]}},{key:"item.agent.state.name",fn:function({ item }){return [_c('span',{style:({cursor: 'pointer', color: item.agent.state.color}),on:{"click":function($event){return _vm.assignServiceToAgent(
              item.agent,
              _vm.currentTicketId,
              item.agent.team.id
            )}}},[_vm._v(" "+_vm._s(item.agent.state.name)+" ")])]}}],null,false,3161830803)}):_vm._e()],1),(_vm.openSuperviseChatDialog)?_c('SuperviseChatDialog',{attrs:{"open":_vm.openSuperviseChatDialog,"chatInfo":_vm.superviseChatInfo},on:{"close":function($event){_vm.openSuperviseChatDialog = !_vm.openSuperviseChatDialog}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }