<template>
  <v-list color="#e5e5e5">
    <!-- v-for="objMessage in agentQueue" :key="objMessage.id" -->
    <div color="white" ref="chatBody" id="chatBody">
     <!--  <v-layout justify-center py-2>
        <v-card
          height="24"
          width="100"
          class="d-flex justify-center d-flex align-center"
          shaped
        >
          <span v-text="agentQueue[0].id" :style="styleSpan" />
        </v-card>
      </v-layout> -->
      <!-- :id="index == currentChat.length - 1 ? 'lastMessage' : ''" -->
      <v-list-item
        v-for="(item, index) in currentChat"
        :key="index"
        :id="
          currentChat.length-1 == index
            ? 'lastChat'
            : ''
        "
        :ref="
          objSearchMessage && item.id === objSearchMessage.id
            ? 'scrollToMe'
            : ''
        "
        :class="
          objSearchMessage && item.id === objSearchMessage.id
            ? 'scrollToMe'
            : ''
        "
      >
        <v-layout
          v-if="item"
          :justify-start="item.isExternal"
          :justify-end="!item.isExternal"
          py-1
          @dblclick="emitReplyMessage(item)"
        >
        <!-- :color="item.sender_user_id[0] != userId ? 'white' : '#d9fdd3'" -->
          <v-card
            max-width="500px"
            min-width="80px"
            class="px-1 py-1 position-relative"
            :style="radius7"
            :color="item.isExternal ? 'white' : '#d9fdd3'"
            @mousemove="(menuId = item.id), (showMenu = false)"
            @mouseleave="mouseLeave()"
          >
            <div style="cardBox" class="position-absolute top-right">
              <!-- <span style="whiteSpacePre" v-text="' '" /> -->
              <v-menu
                v-if="menuId === item.id || (menuId === item.id && showMenu)"
                v-model="showMenu"
                style="mw600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="reply"
                    color="rgba(0,0,0, 0)"
                    height="20"
                    left
                    elevation="0"
                    x-small
                  >
                    <v-icon> expand_more </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menu, index) in item.type === 'message'
                      ? menuItems
                      : menuAttachItems"
                    :key="index"
                    @click="clickMenu(menu, item)"
                  >
                    <v-list-item-title style="listTitle">
                      {{ menu.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="d-flex flex-column-reverse">
              <div v-if="!item.isMedia" class="d-flex justify-space-between align-end ">
                <span>{{ item.content }} </span>
                <span class="message_time">{{ formatTimeToScreen(item.message_time) }}</span>
              </div>
              <div
                v-if="item.isMedia && gfunc.getMediaType(item) == 'Foto'"
                @click="clickMessage(item)" class="pa-1 pt-3"
              >
                <v-img
                  :lazy-src = setSrcImage(item)
                  :src = "setSrcImage(item)"
                  style="border-radius: 10px"
                  max-height="350px"
                  max-width="250px"
                />
              </div>
              <div v-if="item.isMedia && gfunc.getMediaType(item) == 'Áudio'" class="pt-3">
                <audio controls :src="setSrcImage(item)" style="border-radius: 24px"></audio>
              </div>
              <div v-if="item.isMedia && gfunc.getMediaType(item) == 'Vídeo'" class="pa-1 pt-3">
                <video controls width="250px" height="350px" style="border-radius: 16px;">
                  <source :src="setSrcImage(item)"/>
                </video>
              </div>
              <div 
                v-if="item.isMedia && ['Doc', 'Excel', 'Pdf', 'Txt'].includes(gfunc.getMediaType(item))"
                class="pa-1 pt-3 d-flex justify-start align-center"
                @click="clickMessage(item)"
                style="cursor: pointer;"
              >
                <v-icon color="#8b8b8b">
                  {{ fileIcons(item) }}
                </v-icon>
                <span style="color: #8b8b8b; font-size: 14px;" class="pl-1">
                  Arquivo {{  gfunc.getMediaType(item) }}
                </span>
                <v-icon color="#8b8b8b" class="pl-6">
                  arrow_circle_down
                </v-icon>
              </div>
              <div
                v-if="item.quoted_message"
                elevation="0"
                :style="replyMessageBox(item)"
                class="pl-3 mousePointer"
                @click="goToReplyMessage(item)"
              >
                <span class="font-weight-bold" :style="spanReplyMessage(item)">
                  {{
                    item.quoted_message.isExternal
                      ? "Cliente"
                      : "Você"
                  }}
                </span>
                <br />
                <div
                  v-if="item.quoted_message.isMedia"
                  class="d-flex justify-start align-center pr-8 pb-1"
                >
                  <v-icon color="#8b8b8b">
                    {{ gfunc.getMediaIcon(item.quoted_message) }}
                  </v-icon>
                  <span style="replyMessageId; font-size: 14px; color: #8b8b8b;" class="d-inline-block text-truncate pl-1" >
                    Arquivo {{ gfunc.getMediaType(item.quoted_message) }}
                  </span>
                </div>
                <div v-else>
                  <p
                    :style="replyMessageId"
                    class="d-inline-block text-decoration-underline"
                    v-if="item.quoted_message.content.includes('http')">
                    {{ item.quoted_message.content }}
                  </p>
                  <span
                    v-else
                    style="replyMessageId"
                    class="d-inline-block">
                    {{ item.quoted_message.content }}
                </span>
                </div>
              </div>
            </div>
          </v-card>
        </v-layout>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import moment from "moment";
import gfunc from '@/mixins/generic_functions'
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Messages",

  props: {
    objSearchMessage: {
      type: Object,
      default: null,
    },
    currentChat: []
    ,
    reply: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      gfunc: gfunc,
      menuId: -1,
      showMenu: false,
      isDownload: [],
      menuItems: [{ id: 1, title: "Responder" }],
      menuAttachItems: [
        { id: 1, title: "Responder" }
      ],
      scrollToMessage: false,
    };
  },

  mounted() {
    this.goToLastMessage();

    setInterval(() => {
      this.scrollToElement();
    }, 300);
  },

  methods: {
    ...mapActions({
      downloadAttachment: "Attachment/downloadAttachment",
      getMediaType: "Chat/getMediaType",
      getMediaIcon: "Chat/getMediaIcon",
    }),

    goToLastMessage() {
      const cardBodyElement = document.getElementById("cardBody")

      if (Boolean(cardBodyElement)) {
        const chatHeight = Boolean(cardBodyElement) ? cardBodyElement.scrollTop : 0

        if (chatHeight >= 0) {
          const lastChat = document.getElementById("lastChat")

          if (Boolean(lastChat)) {
            setTimeout(() => {
              cardBodyElement.scrollTo({
                top: lastChat.offsetHeight,
                behavior: 'smooth'
              })
            }, 300)
          }
        }
      }
    },

    scrollToElement() {
      const element = this.$refs.scrollToMe;
      if (element && element.length > 0 && !this.scrollToMessage) {
        this.scrollToMessage = true;
        element[0].$el.scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          this.$emit('goToMessage', null)
        }, 600);
      }
    },

    async clickMenu(menu, message) {
      switch (menu.id) {
        case 1:
          this.emitReplyMessage(message);
          break;

        case 2:
          await this.download(message);
          break;

        default:
          break;
      }
    },

    emitReplyMessage(message) {
      this.$emit("replyMessage", message)
    },

    async clickMessage(item) {
      if (this.gfunc.getMediaType(item) == 'Foto'){
        this.$emit("openImage", this.setSrcImage(item));
      }
      if (['Doc', 'Excel', 'Pdf', 'Txt'].includes(this.gfunc.getMediaType(item))){
        await this.download(item);
      }
    },

    async download(item) {
      await this.downloadAttachment({
        token: this.userToken,
        id: item.attachment.id,
      });
    },

    fileIcons(item) {
      if (item.attachment.mimetype.includes("pdf")) return "picture_as_pdf";
      else if (item.attachment.mimetype.includes("sheet")) return "grid_view";
      return "description";
    },

    setSrcImage(item) {
      return item.attachment.url;
    },

    mouseLeave() {
      if (this.showMenu == false) {
        this.menuId = -1;
      }
    },

    formatTimeToScreen(date) {
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("HH:mm");
      return formatedDate;
    },

    formatDateToScreen(date) {
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("DD/MM/YYYY");
      return formatedDate;
    },

    messageIcon(item) {
      if (typeof item.id === "number") {
        return "done_all";
        // switch (item.received) {
        //   case true:
        //   case false:
        //     return "done";
        // }
      } else {
        return "schedule";
      }
    },

    messageIconColor(item) {
      return "blue";
      // if (item.visualized) {
      // } else {
      //   return "";
      // }
    },

    replyMessageBox(item) {
      return `width: 100%; border-radius: 4px;border-left: 4px solid ${
        item.isExternal ? "#53bdeb" : "#06cf9c"
      };background-color: ${
        item.isExternal ? "#f5f6f6" : "#d1f4cc"
      }`;
    },

    spanReplyMessage(item) {
      return `font-family: 'Poppins', sans-serif;font-size: 10.0px;color: ${
        item.isExternal ? "#53bdeb" : "#06cf9c"
      }`;
    },
    goToReplyMessage(item){
      this.$emit('goToMessage', item.quoted_message)
    }
  },

  computed: {
    ...mapGetters({
      usersList: "User/getUsersList",
      userToken: "Login/getToken",
      userAvatar: "User/getUserImage",
      userId: "User/getUserId",
      messagesList: "Chat/getMessagesList",
      agents: "Ws/getAgents",
      // currentChat: "Ws/getCurrentChat"
    }),
    styleSpan() {
      return "font-family: 'Poppins', sans-serif; font-size: 12px; color:grey";
    },

    radius7() {
      return "border-radius: 7px";
    },

    cardBox() {
      return "margin-bottom: -24px; z-index: 0";
    },

    whiteSpacePre() {
      return "white-space: pre";
    },

    mw600() {
      return "max-width: 600px;";
    },

    listTitle() {
      return "font-family: 'Poppins', sans-serif;font-size: 12.0px;color:#111B21";
    },

    replyMessageId() {
      return "font-family: 'Poppins', sans-serif; font-size: 11.0px; color:#111B21; max-width: 100px;";
    },

    notImage() {
      return "width: 32px;\
              height: 32px;\
              border-radius: 5px;\
              background-color: rgb(66, 102, 245, 0.8);";
    },

    spanMessage() {
      return "font-family: 'Poppins', sans-serif;font-size: 13px;color:#111B21;";
    },

    mt16Minus() {
      return "margin-top: -16px";
    }
  },

  watch: {
    showMenu: function () {
      if (!this.showMenu) this.menuId = -1;
    },

    objSearchMessage: function () {
      this.scrollToMessage = false;
    },
  },
};
</script>
<style scoped>

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-right {
  top: 0px;
  right: 2px;
}
.message_time {
  font-size: 10px;
  margin-left: 5px;
}

.mousePointer:hover {
  cursor: pointer;
}
.scrollToMe {
  animation-name: my-animation;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: 4;
  animation-timing-function: linear;
  background-color: #9fa0a1;
  border-radius: 7px;
}
@keyframes my-animation {
  from {
    background-color: #e5e5e5;
  }
  to {
    background-color: #9fa0a1;
  }
}
</style>
