<template>
  <v-card rounded="xl" style="overflow: hidden">
    <div v-if="agentChats.length > 0" class="box" @keydown.esc="cleanMenus()">
      <SearchMessage ref="compSearchMessage" @clickMessage="setSearchMessage" />
      <v-layout style="height: calc(100vh - 85px)">
        <template>
          <div class="d-flex applicantBox">
            <v-navigation-drawer
              permanent
              floating
              :mini-variant="showApplicant"
              width="200px"
              :color="Boolean(banner) ? banner['page_bg_color'] : 'white'"
            >
              <v-list dense class="py-0">
                <v-list-item
                  v-for="(chat, indexChat) in agentChats"
                  :key="indexChat"
                  class="px-0"
                  @click="selectQueue(chat.id, userAgentId)"
                  link
                >
                  <!-- <v-list-item-avatar
                    class="d-flex justify-center"
                    rounded="circle"
                    color="indigo"
                    size="35"
                  >
                    <span
                      class="white--text font-weight-bold"
                      :style="`font-size: ${$gVar.smallText} !important`"
                    >
                      {{ chat ? chat.id : "" }}
                    </span>
                  </v-list-item-avatar> -->
                  <div>
                  <v-list-item-content class="pl-4 pt-0">
                    <v-list-item-title
                      class="text-caption"
                      :style="`color: ${
                        Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                      }`"
                    >
                      {{ gfunc.mobileMask(chat.name) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip x-small>
                        {{ chat.ticket }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-divider /></div>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
            <v-btn
              v-if="$vuetify.breakpoint.mdAndDown"
              icon
              @click="showApplicant = !showApplicant"
              small
              class="my-auto showApplicantBtn"
              elevation="1"
              :style="`background-color: ${
                Boolean(banner) ? banner['page_bg_color'] : 'white'
              }`"
            >
              <v-icon
                :color="Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'"
              >
                {{ !showApplicant ? "chevron_left" : "chevron_right" }}
              </v-icon>
            </v-btn>
          </div>
        </template>
        <v-layout column>
          <v-card
            elevation="0"
            :height="headerHeight"
            class="rounded-0"
            :color="Boolean(banner) ? banner['page_bg_color'] : 'white'"
            width="100%"
          >
            <v-card-title
              class="py-0 d-flex justify-space-between"
              style="height: 40px; border-left: 1px solid rgba(0, 0, 0, 0.2);"
            >
              <div class="d-flex flex-row align-center">
                <!-- <v-avatar
                  class="d-flex justify-center"
                  rounded="circle"
                  color="indigo"
                  size="35"
                  v-if="Boolean(currentChat !== null)"
                >
                  <span
                    class="white--text"
                    :style="`font-size: ${$gVar.smallText} !important`"
                  >
                    {{
                      Boolean(currentChat !== null) &&
                      agentChats.length > 0 &&
                      Boolean(agentChats[queueSelected])
                        ? agentChats[queueSelected].id
                        : ""
                    }}
                  </span>
                </v-avatar> -->
                <div class="d-flex flex-column ml-1">
                  <span
                    class="text-caption"
                    :style="`color: ${
                      Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                    }`"
                  >
                    {{
                      !showApplicant
                        ? $vuetify.breakpoint.lgAndUp &&
                          agentChats.length > 0 &&
                          Boolean(agentChats[queueSelected]) &&
                          Boolean(currentChat !== null)
                          ? gfunc.mobileMask(agentChats[queueSelected].name)
                          : ""
                        : agentChats.length > 0 &&
                          Boolean(agentChats[queueSelected]) &&
                          Boolean(currentChat !== null)
                        ? gfunc.mobileMask(agentChats[queueSelected].name)
                        : ""
                    }}
                  </span>
                  <v-chip x-small>
                    <span style="font-size: 12px; font-weight: 400">
                      {{ agentChats[queueSelected].ticket }}
                    </span>
                  </v-chip>
                </div>
              </div>
              <v-menu
                offset-y
                rounded="lg"
                v-if="
                  Boolean(currentChat !== null) &&
                  agentChats.length > 0 &&
                  Boolean(agentChats[queueSelected])
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon
                      :color="
                        Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                      "
                    >
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <div
                    v-for="(item, index) in [
                      {
                        icon: 'done',
                        title: 'Encerrar atendimento',
                        action: () => closeTicket(),
                      },
                      // {
                      //   icon: 'trending_up',
                      //   title: 'Transferir atendimento',
                      // },
                      // {
                      //   icon: 'search',
                      //   title: 'Procurar',
                      // },
                    ]"
                    :key="index"
                  >
                    <v-list-item link class="py-0" @click="item.action">
                      <v-list-item-icon>
                        <v-icon>
                          {{ item.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="text-caption">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-divider class="pb-0" v-if="index < 2" /> -->
                  </div>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-layout> </v-layout>
          </v-card>
          <v-card
            elevation="0"
            @scroll="currentHeight"
            :height="calcViewHeight"
            color="#e5e5e5"
            ref="compMessages"
            id="compMessages"
            class="d-flex align-end flex-column-reverse overflow-auto overflow-x-hidden rounded-0"
          >
            <div :style="w100">
              <Messages
                :objSearchMessage="objSearchMessage"
                @replyMessage="setReplyMessage"
                @openImage="setImage"
                @goToMessage="goToMessage($event)"
                :currentChat="currentChat"
              />
              <AttachmentFile
                v-show="showAttachmentList"
                :style="`height: ${footerHeight}px; background-color: ${frontColor}`"
                :model="'chat.message'"
                ref="compAttachment"
                @hasAttachments="(showAttachmentList = true), (userMessage = '')"
                @noAttachments="showAttachmentList = false"
              />
            </div>
            <v-layout v-if="chatHeight < 0" class="goToStart" pr-6 pb-6>
              <v-btn
                fab
                :color="Boolean(banner) ? banner['page_bg_color'] : 'primary'"
                small
                elevation="2"
                @click="goToStartMessages()"
              >
                <v-icon color="white"> arrow_downward </v-icon>
              </v-btn>
            </v-layout>
            <v-layout
              v-if="chatLoading"
              justify-center
              align-center
              :style="w100"
            >
              <div
                class="d-flex justify-center align-center rounded-circle"
                style="height: 80px"
              >
                <v-progress-circular :size="50" color="blue" indeterminate />
              </div>
            </v-layout>
          </v-card>

          <v-card
            v-if="showReplyMessage"
            elevation="0"
            :color="frontColor"
            :style="`${w100}; padding: 8px 0 2px 0;`"
            class="pl-10 d-flex align-center rounded-0"
          >
            <v-card
              v-if="messageReply"
              color="#e5e7ea"
              elevation="0"
              :height="footerHeight"
              :style="`width: 100%; border-radius: 4px;border-left: 4px solid ${
                messageReply.isExternal
                  ? '#53bdeb'
                  : '#06cf9c'
              };`"
              class="pl-3"
            >
              <span
                class="font-weight-bold"
                :style="`font-family: 'Poppins', sans-serif;font-size: 11.0px;color:${
                  messageReply.isExternal
                    ? '#53bdeb'
                    : '#06cf9c'
                }`"
              >
                {{
                  messageReply.isExternal
                    ? "Cliente"
                    : "Você"
                }}
              </span>
              <br />
              <div
                  v-if="messageReply.isMedia"
                  class="d-flex justify-start align-center pr-8"
                >
                  <v-icon color="#8b8b8b">
                    {{ gfunc.getMediaIcon(messageReply) }}
                  </v-icon>
                  <span style="replyMessageId; font-size: 14px; color: #8b8b8b;" class="d-inline-block text-truncate pl-1" >
                    Arquivo {{ gfunc.getMediaType(messageReply) }}
                  </span>
                </div>
              <div v-else>
                <p
                  :style="`font-family: 'Poppins', sans-serif;font-size: 12.0px;color:#111B21;max-width: ${replyMaxWidth}px;`"
                  class=""
                >
                  {{ messageReply.content }}
                </p>
              </div>
            </v-card>
            <div class="pl-6" />
            
            <v-btn
              icon
              color="grey"
              height="32"
              width="32"
              @click="closeMessageReply()"
            >
              <v-icon> close </v-icon>
            </v-btn>
            <div class="pr-6" />
          </v-card>
          <v-card
            elevation="0"
            :height="footerHeight"
            :color="frontColor"
            :style="w100"
            class="px-6 d-flex align-center rounded-0"
          >
            <v-layout align-center>
              <v-btn
                v-if="!sendAttachment"
                class="mr-3"
                text
                icon
                color="#54656f"
                large
                outlined
                @click="openSelectFiles()"
              >
                <v-icon> attachment </v-icon>
              </v-btn>
              <div v-else class="pa-2">
                <v-progress-circular
                  indeterminate
                  color="primary">
                </v-progress-circular>
              </div>
              <v-text-field
                ref="textFieldMessage"
                v-model="userMessage"
                clearable
                flat
                dense
                hide-details
                outlined
                color="blue"
                background-color="white"
                :placeholder="
                  showAttachmentList
                    ? 'Mensagem bloqueada'
                    : 'Digite sua mensagem'
                "
                class="rounded-md"
                height="42px"
                @keyup.enter="prepareSendMessages()"
                :disabled="showAttachmentList"
              />
              <v-btn
                class="ml-3"
                text
                icon
                color="#54656f"
                large
                @click="prepareSendMessages()"
              >
                <v-icon>send</v-icon>
              </v-btn>
            </v-layout>
          </v-card>
        </v-layout>
      </v-layout>
    </div>
    <div v-else>
      <v-layout
        class="d-flex justify-center align-center flex-column"
        :style="`height: calc(100vh - 85px); background-color: ${
          Boolean(banner) ? banner['page_bg_color'] : '#C0C0C0'
        }`"
      >
        <v-icon
          size="130"
          class="mb-3"
          :color="
            Boolean(banner) ? banner['page_text_color'] + '8A' : '#FFFFFF8A'
          "
        >
          mdi-face-agent
        </v-icon>
        <span
          class="text-h5"
          :style="`color: ${
            Boolean(banner) ? banner['page_text_color'] + '8A' : '#FFFFFF8A'
          }`"
        >
          SEM ATENDIMENTOS
        </span>
      </v-layout>
    </div>
    <v-dialog v-model="openImage" @keydown.esc="closeImage()" overlay-opacity="1" elevation="0">
        <v-layout class="fill-height" column style="layoutBox" align-end>
          <v-btn icon @click="openImage = !openImage">
            <v-icon large color="white"> close </v-icon>
          </v-btn>
          <v-layout align-center justify-center style="width: 100%">
            <v-img
              contain
              :src="srcImage"
              max-height="calc(100vh - 180px)"
              max-width="auto"
            >
            </v-img>
          </v-layout>
        </v-layout>
      </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import apiState from "@/ilios.store.js";
import Loader from "../Global/Loader.vue";
import Messages from "./components/Messages.vue";
import ShowImage from "./components/ShowImage.vue";
import SearchMessage from "./components/SeachMessage.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AttachmentFile from "./components/AttachmentFile.vue";
import gfunc from '@/mixins/generic_functions'

export default {
  name: "MainComponent",

  components: {
    Messages,
    AttachmentFile,
    ShowImage,
    Loader,
    SearchMessage,
  },

  data() {
    return {
      gfunc: gfunc,
      activeChat: null,
      activeChatObj: null,
      searchUser: "",
      userMessage: "",

      headerHeight: 50,
      footerHeight: 64,
      interval: null,
      wsState: -1,
      finishOrderUsers: false,
      awaitingSearch: false,
      showReplyMessage: false,
      showAttachmentList: false,
      messageReply: null,
      openImage: false,
      srcImage: "",

      lastScrollHeightBottom: 0,
      lastScrollHeightTop: 0,
      objSearchMessage: null,

      showApplicant: false,
      chatSelected: 0,
      chatHeight: 0,
      maxChatScroll: 0,

      sendAttachment: false
    };
  },

  methods: {
    ...mapActions({
      getMessages: "Chat/getMessages",
      getMessagesIds: "Chat/getMessagesIds",
      getLastMessages: "Chat/getLastMessages",
      cleanMessagesList: "Chat/cleanMessagesList",
      getSearchMessage: "Chat/getSearchMessages",
      sendAttachmentMessage: "Chat/sendAttachmentMessage",
      setNextChat: "Ws/setNextChat",
    }),

    ...mapMutations({
      addNewMessage: "Chat/ADD_NEW_MESSAGE",
      setMessageSucessfully: "Chat/SET_MESSAGE_SUCCESSFULLY",
      setMessageReceived: "Chat/SET_MESSAGE_RECEIVED",
      setLastMessages: "Chat/SET_LAST_MESSAGES",
      setNewLastMessage: "Chat/SET_NEW_LAST_MESSAGE",
      setMessageView: "Chat/SET_MESSAGE_VIEW",
      setSearchUsers: "Chat/SET_SEARCH_USERS",
      setMessageList: "Chat/SET_MESSAGES_LIST",
      SET_QUEUE_SELECTED: "Ws/SET_QUEUE_SELECTED",
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
    }),

    getTicketChatMessages(chatId) {
      this.$socket.sendObj({
        db_name: process.env.VUE_APP_DATA_BASE,
        access_token: this.userToken,
        action: "get_ticket_chat_messages",
        body: {
          ticket_id: chatId,
        },
      });
    },

    selectQueue(chatId, userAgentId) {
      this.getTicketChatMessages(chatId)

      this.SET_QUEUE_SELECTED({ chatId: chatId, userAgentId: userAgentId });

      this.closeMessageReply()
    },

    closeTicket() {
      try {
        if (Boolean(this.currentChat[0])) {
          this.$socket.sendObj({
            db_name: process.env.VUE_APP_DATA_BASE,
            access_token: this.userToken,
            action: "close_ticket",
            body: {
              ticket_id: this.currentChat[0].ticket_id,
            },
          });
        }
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },

    goToStartMessages() {
      this.newMsgScroll(true)
      // this.setActiveChat(this.activeChatObj);
    },

    async setSearchMessage(item) {
      if (!this.objSearchMessage || this.objSearchMessage.id != item.id) {
        this.objSearchMessage = item;
        this.lastScrollHeightBottom = 0;
        this.lastScrollHeightTop = 0;
        await this.cleanMessagesList();
        let agoSearchMessage = await this.getSearchMessage({
          token: this.userToken,
          order: "DESC",
          domain: `['&', ('create_date', '<=', '${item.create_date}'), '|',
                        ('sender_user_id', '=', ${item.sender_user_id[0]}),
                        ('receiver_user_id', '=', ${item.sender_user_id[0]})]`,
        });
        let lastSearchMessage = await this.getSearchMessage({
          order: "ASC",
          token: this.userToken,
          domain: `['&', ('create_date', '>', '${item.create_date}'), '|',
                        ('sender_user_id', '=', ${item.sender_user_id[0]}),
                        ('receiver_user_id', '=', ${item.sender_user_id[0]})]`,
        });

        this.setMessageList({
          messagesList: agoSearchMessage.reverse().concat(lastSearchMessage),
        });
      }
    },

    openSearchMessage() {
      this.$refs.compSearchMessage.drawer = true;
    },

    cleanMenus() {
      this.showReplyMessage = false;
    },

    // async getNewMessages({
    //   target: { scrollTop, clientHeight, scrollHeight },
    // }) {
    //   if (
    //     scrollTop + scrollHeight <= clientHeight * 2 &&
    //     !this.chatLoading &&
    //     this.lastScrollHeightTop < scrollHeight
    //   ) {
    //     this.lastScrollHeightTop = scrollHeight;
    //     let lastDateMessage =
    //       this.messageList.length > 0 ? this.messageList[0] : null;
    //     let lasMessageCreateDate =
    //       lastDateMessage && lastDateMessage.messages
    //         ? lastDateMessage.messages[0].create_date
    //         : null;

    //     if (lasMessageCreateDate && typeof lasMessageCreateDate === "string") {
    //       await this.getMessages({
    //         domain: `['&', '|', '&', ('sender_user_id', '=', ${Number(
    //           this.activeChatObj.id
    //         )}),
    //                                 ('receiver_user_id', '=', ${Number(
    //                                   this.userId
    //                                 )}), '&',
    //                                 ('sender_user_id', '=', ${Number(
    //                                   this.userId
    //                                 )}), ('receiver_user_id', '=', ${Number(
    //           this.activeChatObj.id
    //         )}),
    //                                 ('create_date', '<', '${lasMessageCreateDate}')]`,
    //         token: this.userToken,
    //         reverse: false,
    //       });
    //     }
    //   }
    //   if (
    //     scrollTop > -Math.abs(scrollHeight * 0.2) &&
    //     !this.chatLoading &&
    //     this.lastScrollHeightBottom < scrollHeight
    //   ) {
    //     this.lastScrollHeightBottom = scrollHeight;
    //     let lastDateMessage =
    //       this.messageList.length > 0
    //         ? this.messageList[this.messageList.length - 1]
    //         : null;
    //     let lasMessageCreateDate =
    //       lastDateMessage && lastDateMessage.messages
    //         ? lastDateMessage.messages[lastDateMessage.messages.length - 1]
    //             .create_date
    //         : null;

    //     if (typeof lasMessageCreateDate === "string") {
    //       //Verifica se a ultima mensagem da lista, é de fato a ultima mensagem enviada.
    //       let messages = await this.getMessages({
    //         domain: `['&', '|', '&', ('sender_user_id', '=', ${Number(
    //           this.activeChatObj.id
    //         )}),
    //                                 ('receiver_user_id', '=', ${Number(
    //                                   this.userId
    //                                 )}), '&',
    //                                 ('sender_user_id', '=', ${Number(
    //                                   this.userId
    //                                 )}), ('receiver_user_id', '=', ${Number(
    //           this.activeChatObj.id
    //         )}),
    //                                 ('create_date', '>', '${lasMessageCreateDate}')]`,
    //         token: this.userToken,
    //         reverse: true,
    //       });

    //       if (messages && messages.length == 0) {
    //         this.objSearchMessage = null;
    //       }
    //     }
    //   }
    // },

    setImage(srcImage) {
      this.srcImage = srcImage;
      this.openImage = true;
    },

    setReplyMessage(message) {
      if (this.showAttachmentList) {
        this.showAttachmentList = false;
        this.userMessage = "";
        this.$refs.compAttachment.cleanAttachmentList();
      }
      this.messageReply = message;
      this.showReplyMessage = true;
      this.$refs.textFieldMessage.focus();
    },

    openSelectFiles() {
      this.showReplyMessage ? this.closeMessageReply() : false;
      this.$refs.compAttachment.openSelectFiles();
    },

    closeMessageReply() {
      this.showReplyMessage = false;
      this.messageReply = null;
      this.$refs.textFieldMessage.focus();
    },

    async prepareSendMessages() {
      if (this.userMessage) {
        this.sendMessage("message");
      } else if (this.showAttachmentList) {
        this.sendAttachment = !this.sendAttachment
        let fileData = [];

        for (const file of this.$refs.compAttachment.attachment) {
          try {
            const base64 = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result.split(',')[1]);
              reader.onerror = error => reject(error);
              reader.readAsDataURL(file);
            });
            const mimetype = file.type;
            fileData.push({
              media: base64,
              mimetype: mimetype,
              extension: this.gfunc.getMediaExtension(mimetype),
            });
          } catch (error) {
            console.error("Erro ao converter o arquivo para base64", error);
          }
        }
        await this.sendAttachmentMessage({
          token: this.userToken,
          datas: fileData,
          ticketId: this.currentChat[0].ticket_id,
          websocket: this.$socket
        });
        this.showAttachmentList = false;
        this.sendAttachment = !this.sendAttachment
      }
    },

    async sendMessage(type, attachment) {
      let quoted_message;
      if (this.messageReply) {
        quoted_message = {
          id: this.messageReply.id,
          model: this.messageReply.model,
          message: this.messageReply.content,
        }
      }
      let socketDict = {
        db_name: process.env.VUE_APP_DATA_BASE,
        action: "send_message",
        access_token: this.userToken,
        body: {
          ticket_id: this.currentChat[0].ticket_id,
          message: this.userMessage,
          quoted_message: quoted_message,
          attachment_id: null,
        },
      };

      
      this.$socket.sendObj(socketDict);
      
      this.userMessage = "";
      if (quoted_message) {
        this.closeMessageReply()
      }
      document.getElementById("compMessages").scrollTop = 0
    },
    goToMessage(item){
      this.objSearchMessage = item
    },

    // confirmMessageView() {
    //   this.$socket.sendObj({
    //     db_name: process.env.VUE_APP_DATA_BASE,
    //     action: "confirm_message_view",
    //     user_id: String(localStorage.getItem("userId")),
    //     chat_user_id: String(this.activeChatObj.id),
    //   });
    // },

    // async createConnection() {
    //   if (this.$socket.readyState === 1) {
    //     await this.$socket.sendObj({
    //       db_name: process.env.VUE_APP_DATA_BASE,
    //       user_id: localStorage.getItem("userId"),
    //       access_token: this.userToken,
    //       action: "register",
    //     });
    //     this.wsState = this.$socket.readyState;
    //   }
    // },

    // async markRecived(data) {
    //   let dataObj = JSON.parse(data.data);
    //   switch (dataObj.action) {
    //     case "message_sent_successfully":
    //       this.setMessageSucessfully({
    //         objMessage: {
    //           message_date: this.formatDateToScreen(dataObj.message_date),
    //           fe_message_id: dataObj.fe_message_id,
    //           chat_message_id: dataObj.chat_message_id,
    //           attachment_id: dataObj.attachment_id,
    //         },
    //       });
    //       break;
    //     case "send_message":
    //       let messageVisualized = false;
    //       this.$socket.sendObj({
    //         db_name: process.env.VUE_APP_DATA_BASE,
    //         user_id: localStorage.getItem("userId"),
    //         sender_user_id: dataObj.sender_user_id,
    //         chat_message_id: dataObj.chat_message_id,
    //         message_date: dataObj.message_date,
    //         action: "set_message_received",
    //       });
    //       if (
    //         this.activeChatObj &&
    //         Number(dataObj.sender_user_id) === this.activeChatObj.id
    //       ) {
    //         messageVisualized = true;
    //         this.addNewMessage({
    //           objMessage: {
    //             id: dataObj.chat_message_id,
    //             sender_user_id: dataObj.sender_user_id,
    //             receiver_user_id: [
    //               Number(localStorage.getItem("userId")),
    //               this.userName,
    //             ],
    //             message: dataObj.message,
    //             type: dataObj.type,
    //             attachment_id: dataObj.attachment_id,
    //             create_date: dataObj.message_date,
    //             received: true,
    //             visualized: messageVisualized,
    //             reply_message_id: dataObj.reply_message_id,
    //             reply_msg_user: this.messageReply
    //               ? this.messageReply.sender_user_id
    //               : null,
    //           },
    //         });
    //         this.confirmMessageView();
    //       }
    //       let lastUser = this.lastUsers.find(
    //         (el) => el.id === Number(dataObj.sender_user_id)
    //       );
    //       if (!lastUser) {
    //         lastUser = this.usersList.find(
    //           (el) => el.id === Number(dataObj.sender_user_id)
    //         );
    //       }
    //       if (lastUser) {
    //         this.setNewLastMessage({
    //           lastMessage: {
    //             id: dataObj.chat_message_id,
    //             sender_user_id: Number(dataObj.sender_user_id),
    //             receiver_user_id: Number(localStorage.getItem("userId")),
    //             message: dataObj.message,
    //             type: dataObj.type,
    //             create_date: dataObj.message_date,
    //             visualized: messageVisualized,
    //           },
    //           objUser: lastUser,
    //           isObjLastUser: true,
    //         });
    //       }

    //       this.newMsgScroll();
    //       break;
    //     case "message_received_successfully":
    //       this.setMessageReceived({
    //         objMessage: {
    //           message_date: this.formatDateToScreen(dataObj.message_date),
    //           chat_message_id: dataObj.chat_message_id,
    //         },
    //       });
    //       break;
    //     case "notify_message_view":
    //       if (
    //         this.activeChatObj &&
    //         this.activeChatObj.id === Number(dataObj.sender_user_id)
    //       ) {
    //         this.setMessageView({
    //           userChat: Number(dataObj.sender_user_id),
    //         });
    //       }
    //       break;
    //     case "error":
    //       alert(
    //         "Não foi possível marcar mensagem como recebida: ",
    //         dataObj.message
    //       );
    //       break;
    //   }
    //   this.wsState = this.$socket.readyState;
    // },

    async newMsgScroll(noDelay) {
      if (noDelay){
        document.getElementById("compMessages").style.scrollBehavior = "smooth";
        document.getElementById("compMessages").scrollTop = 0;
        return
      }
      await new Promise((r) => setTimeout(r, 400));
      document.getElementById("compMessages").style.scrollBehavior = "smooth";
      document.getElementById("compMessages").scrollTop = 0;
    },

    wsOnError() {
      this.wsState = this.$socket.readyState;
    },

    wsOnClose() {
      this.wsState = -1;
    },

    // setActiveChat(obj) {
    //   if (!this.chatLoading) {
    //     this.cleanMessagesList();
    //     this.activeChatObj = null;
    //     this.activeChatObj = obj;
    //     this.lastScrollHeightBottom = 0;
    //     this.lastScrollHeightTop = 0;
    //     this.setNewLastMessage({
    //       lastMessage: {
    //         id: 1,
    //         sender_user_id: Number(localStorage.getItem("userId")),
    //         receiver_user_id: Number(this.activeChatObj.id),
    //         message: "",
    //         create_date: new Date(
    //           new Date().valueOf() + new Date().getTimezoneOffset() * 60000
    //         ),
    //       },
    //       objUser: this.activeChatObj,
    //     });
    //     this.userMessage = "";
    //     this.searchUser = "";
    //     this.closeMessageReply();
    //   }
    // },

    async openChatUser(talkUser) {
      this.$refs.compSearchMessage.cleanForm();
      this.objSearchMessage = null;
      await this.getMessages({
        domain: `['&', '|', '&', ('sender_user_id', '=', ${Number(
          talkUser.id
        )}),
                                 ('receiver_user_id', '=', ${Number(
                                   this.userId
                                 )}), '&',
                                 ('sender_user_id', '=', ${Number(
                                   this.userId
                                 )}), ('receiver_user_id', '=', ${Number(
          talkUser.id
        )}),
                                 ('create_date', '<=', '${moment(new Date())
                                   .locale("pt-br")
                                   .format("YYYY-MM-DD")}')]`,
        token: this.userToken,
      });
      this.confirmMessageView();
      if (talkUser.lastMessage) {
        talkUser.lastMessage["visualized"] = true;
      }
    },

    renderFinish() {
      //if (this.usersList && this.userAvatar != '' && this.finishOrderUsers) {
      if (this.usersList) {
        return true;
      }
    },

    makeId() {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    formatDateToScreen(date) {
      let newDate = new Date(date);
      let formatedDate = new Date(
        newDate.valueOf() - newDate.getTimezoneOffset() * 60000
      );
      formatedDate = moment(formatedDate).locale("pt-br").format("DD/MM/YYYY");
      return formatedDate;
    },

    formatDateMessage(item) {
      if (item.lastMessage && item.lastMessage.create_date) {
        let newDate = new Date(item.lastMessage.create_date);
        let formatedDate = new Date(
          newDate.valueOf() - newDate.getTimezoneOffset() * 60000
        );
        if (formatedDate.getDay() === new Date().getDay()) {
          return moment(formatedDate).locale("pt-br").format("HH:mm");
        } else {
          return moment(formatedDate).locale("pt-br").format("DD/MM/YYYY");
        }
      }
      return "";
    },

    // isNewMessage(item) {
    //   if (
    //     item.lastMessage &&
    //     item.lastMessage.visualized === false &&
    //     item.lastMessage.sender_user_id !=
    //       Number(localStorage.getItem("userId"))
    //   ) {
    //     if (this.activeChatObj) {
    //       if (this.activeChatObj.id != item.lastMessage.sender_user_id) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     } else {
    //       return true;
    //     }
    //   }
    //   return false;
    // },

    setAvatarName(name) {
      var firstLetter = name.charAt(0);
      var lastLetter = "";
      var lastSpace = name.lastIndexOf(" ");
      if (lastSpace > 0) {
        lastLetter = name.substring(lastSpace + 1, lastSpace + 2);
      }
      return (firstLetter + lastLetter).toUpperCase();
    },
    currentHeight() {
      const cardBodyElement = document.getElementById("compMessages")
      this.chatHeight = Boolean(cardBodyElement) ? cardBodyElement.scrollTop : 0
      this.maxChatScroll = cardBodyElement.scrollHeight - cardBodyElement.offsetHeight
    },
  },

  // async mounted() {
  //   this.$connect()
  //   window.addEventListener("webSocketOnOpen", this.$socket.onopen = (data) => this.createConnection())
  //   window.addEventListener("webSocketOnMessage", this.$options.sockets.onmessage = (data) => this.markRecived(data))
  //   window.addEventListener("webSocketOnError", this.$options.sockets.onerror = (data) => this.wsOnError())
  // },

  computed: {
    ...mapGetters({
      usersList: "User/getUsersList",
      userToken: "Login/getToken",
      userAvatar: "User/getUserImage",
      userId: "User/getUserId",
      chatState: "Chat/getApiState",
      messageList: "Chat/getMessagesList",
      lastUsers: "Chat/getLastUsers",
      searchUsersList: "Chat/getSearchUsers",
      userName: "User/getUserName",
      banner: "Panel/getBanner",
      agents: "Ws/getAgents",
      userAgentId: "User/getUserAgentId",
      queueSelected: "Ws/getQueueSelected",
      currentChat: "Ws/getCurrentChat",
    }),

    agentChats() {
      if (this.agents.length > 0) {
        const agentIndex = this.agents.findIndex(
          (el) => el.agent.id == this.userAgentId
        );
        if (agentIndex > -1) {
          return this.agents[agentIndex].agent.chats.filter(
            (el) => el.state === "open"
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },

    chatLoading() {
      return this.chatState === apiState.LOADING;
    },

    viewHeight() {
      return window.innerHeight;
    },

    frontColor: () => "#f0f2f5",

    cardBox: () => "overflow-y: auto;overflow-x: hidden; border-radius: 0px;",

    w415: () => "width: 415px;",

    w65: () => "width: 65px;",

    spanName: () =>
      "font-family: 'Poppins', sans-serif;font-size: 14.0px; color:#111B21; max-width: 255px",

    messageBox: () =>
      "width: 15px; height: 15px; border-radius: 100%; background: #25d366;",

    activeChatBox: () =>
      "border-left: 1px solid grey; width: 10px; height: 42px;margin-left: 10px; margin-right: 3px; margin-top: 10px",

    w100: () => "width: 100%",

    wsStateLabel() {
      switch (this.wsState) {
        case 1:
          return "Conectado";
        case 3:
          return "Reconectando...";
        default:
          return "Desconectado";
      }
    },

    replyMaxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 500;
        case "lg":
          return 800;
        case "xl":
          return 1300;
      }
    },

    usersFilterList() {
      return this.searchUser
        ? this.searchUsersList.filter((el) => el.id != this.userId)
        : this.lastUsers;
    },

    calcViewHeight() {
      if (this.showReplyMessage || this.showAttachmentList) {
        return (
          this.viewHeight -
          this.headerHeight -
          this.footerHeight -
          this.footerHeight -
          2
        );
      } else {
        return this.viewHeight - this.headerHeight - this.footerHeight - 2;
      }
    },
  },
  watch: {
    userAvatar: async function () {
      let result = await this.getLastMessages({
        token: this.userToken,
      });
      this.setLastMessages({
        lastMessages: result,
      });
      this.finishOrderUsers = true;
    },

    // activeChat: function () {
    //   if (this.activeChat < 0 || this.activeChat === null) {
    //     this.cleanMessagesList();
    //     this.activeChatObj = null;
    //   }
    // },

    searchUser: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.setSearchUsers({
            userName: this.searchUser,
          });
          this.awaitingSearch = false;
        }, 1500);
      }
      this.awaitingSearch = true;
    },

    // activeChatObj: function () {
    //   if (this.activeChatObj) {
    //     this.openChatUser(this.activeChatObj);
    //   }
    // },
  },

  destroyed() {
    clearInterval(this.interval);
  },
};
</script>
<style scoped>
.goToStart {
  position: fixed;
}

.box {
  display: flex;
  flex-flow: column;
  /* overflow-y: scroll; */
}

/* .box {
  border: 1px dotted grey;
} */

/* .box .header {
  flex: 0 1 auto;
  background-color: white;
  width: 400px;
} */

.showApplicantBtn {
  z-index: 2;
  background-color: white;
  position: absolute;
  right: 0;
  top: 50%;
  margin-right: -14px;
}
.applicantBox {
  position: relative;
  background-color: transparent;
  /* border-right: 1px solid rgba(0, 0, 0, 0.12); */
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #afb0b3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #737475;
}
</style>
