<template>
  <v-dialog scrollable width="500" v-model="open" @click:outside="closeDialog()">
    <template v-slot:default="{ isActive }">
      <v-card
        class="pa-4">
        <v-card-title class="d-flex justify-space-between pa-0 pb-2 align-start">
          <div class="d-flex flex-column">
            <div>
              <p class="ma-0">Chat - {{ chatInfo.ticket.name }}</p>
            </div>
            <div class="mt-2 d-flex flex-row justify-space-between px-0" style="font-size: 14px; font-weight: 700;">
              <p class="ma-0">Agente: <span style="font-weight: 400 !important;">{{ chatInfo.agentName }}</span></p>
              <p class="ma-0">Time: <span style="font-weight: 400 !important;">{{ chatInfo.teamName }}</span></p>
            </div>
            <div class="mb-2 d-flex flex-row justify-space-between px-0" style="font-size: 14px; font-weight: 700;">
              <p class="ma-0">Status: <span style="font-weight: 400 !important;">{{ chatInfo.ticket.state }}</span></p>
            </div>
          </div>
          <v-btn color="red" depressed x-small class="white--text" fab elevation="1" @click="closeDialog()">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="mb-10 pa-0 mr-5"
          id="cardBody"
          style="border-radius: 8px; background-color: #d6d6d6;"
          @scroll="currentHeight"
        >
          <Messages
            :currentChat="superviseChat.messages"
            :reply="false"
          />
          <v-btn
            fab
            v-if="chatHeight < maxChatScroll"
            style="
              position: absolute;
              z-index: 999;
              bottom: 80px;
              right: 50px;
            "
            :color="Boolean(banner) ? banner['page_bg_color'] : 'primary'"
            small
            elevation="2"
            @click="goToLastMessage()"
          >
            <v-icon color="white"> arrow_downward </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import Messages from '@/components/Chat/components/Messages.vue'
import { mapGetters, mapMutations } from "vuex";

export default {

  name: 'SuperviseChatDialog',
  props: {
    open: {
      default: false,
      type: Boolean
    },
    chatInfo: {
      default: {},
      type: Object
    }
  },
  components: {
    Messages
  },
  data: () => ({
    chatHeight: 0,
    maxChatScroll: 0,
    objSearchMessage: null,
  }),
  methods: {
    ...mapMutations({
      SET_SUPERVISE_CHAT: "Ws/SET_SUPERVISE_CHAT"
    }),
    goToLastMessage() {
      const cardBodyElement = document.getElementById("cardBody")
      const chatHeight = Boolean(cardBodyElement) ? cardBodyElement.scrollTop : 0

      if (cardBodyElement >= 0) {
        setTimeout(() => {
          cardBodyElement.scrollTo({
            top: cardBodyElement.offsetHeight,
            behavior: 'smooth'
          })
        }, 300)
      }
    },
    closeDialog() {
      this.SET_SUPERVISE_CHAT({close: true})
      this.$emit('close')
    },
    superviseTicket(ticketId){
      this.$socket.sendObj({
        db_name: process.env.VUE_APP_DATA_BASE,
        access_token: this.userToken,
        action: "get_supervise_chat_messages",
        body: {
          ticket_id: ticketId,
        },
      });
    },
    currentHeight() {
      const cardBodyElement = document.getElementById("cardBody")
      if (Boolean(cardBodyElement)) {
        this.maxChatScroll = cardBodyElement.offsetHeight
        this.chatHeight = cardBodyElement.scrollTop
      }
    },
  },
  computed: {
    ...mapGetters({
      superviseChat: "Ws/getSuperviseChat",
      userToken: "Login/getToken",
      banner: "Panel/getBanner",
    }),
  },
  mounted() {
    this.superviseTicket(this.chatInfo.ticket.id)
  },
}
</script>