<template>
  <v-snackbar
    v-model="showSnackbar"
    timeout="2000"
    :color="snackbarColor"
  >
    {{ snackbarMessage }}
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    wsState: null,
    wsInterval: null,
    wsIntervalConnection: null,
    dataAgentId: null,
    connecting: false,
    snackbarMessage: '',
    showSnackbar: false,
    snackbarColor: '',
    closeTicketId: null
  }),
  watch: {
    loggedIn: async function (newValue, oldValue) {
      if (newValue === true) {
        const agentData = await this.setUserTeam({
          token: this.userToken,
          userId: this.userId
        })
        if (Boolean(agentData)) {
          this.dataAgentId = agentData.agentId
          await this.setWsConnection(agentData.teamId, agentData.agentId)
        }
      }
    },
    agents: function (newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        const agentId = Boolean(this.userAgentId == null) ? this.dataAgentId : this.userAgentId
        const indexAgent = newValue.findIndex(el => el.agent.id === agentId)
        if (newValue[indexAgent].agent.chats.length > 0) {
          try {
            const activeChat = newValue[indexAgent].agent.chats.find(el => el.state === "open")
            if (Boolean(activeChat)) {
              this.$socket.sendObj({
                db_name: process.env.VUE_APP_DATA_BASE,
                access_token: this.userToken,
                action: "get_ticket_chat_messages",
                body: {
                  ticket_id: activeChat.id
                }
              });
            }
          } catch (e) {
            const messageError = e.response ? e.response.data.message : e;
            this.SET_MESSAGE_ERROR({
              message: messageError,
            });
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setUserTeam: "User/setUserTeam"
    }),
    ...mapMutations({
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
      SET_ALL_AGENTS: "Ws/SET_ALL_AGENTS",
      SET_QUEUE: "Ws/SET_QUEUE",
      REMOVE_TICKET_OF_QUEUE: "Ws/REMOVE_TICKET_OF_QUEUE",
      APPEND_CHAT_TO_AGENT: "Ws/APPEND_CHAT_TO_AGENT",
      UPDATE_TICKETS_QUEUE: "Ws/UPDATE_TICKETS_QUEUE",
      GET_TICKET_CHAT_MESSAGES: "Ws/GET_TICKET_CHAT_MESSAGES",
      SET_AGENT_MESSAGE_SENDED: "Ws/SET_AGENT_MESSAGE_SENDED",
      UPDATE_TICKET_CHAT_MESSAGE: "Ws/UPDATE_TICKET_CHAT_MESSAGE",
      CLOSE_TICKET: "Ws/CLOSE_TICKET",
      SET_WS_STATUS: "Ws/SET_WS_STATUS",
      SET_AGENT_STATUS: "Ws/SET_AGENT_STATUS",
      CHANGE_AGENT_STATUS: "Ws/CHANGE_AGENT_STATUS",
      SET_NEW_AGENT_IN_TEAM: "Ws/SET_NEW_AGENT_IN_TEAM",
      ASSIGN_SERVICE_TO_AGENT: "Ws/ASSIGN_SERVICE_TO_AGENT",
      UPDATE_TICKET_STATE: "Ws/UPDATE_TICKET_STATE",
      SET_AVAILABLES_STATUS_LIST: "User/SET_AVAILABLES_STATUS_LIST",
      SET_SUPERVISE_CHAT: "Ws/SET_SUPERVISE_CHAT",
      UPDATE_SUPERVISE_CHAT_MESSAGE: "Ws/UPDATE_SUPERVISE_CHAT_MESSAGE",
      SET_QUEUE_SELECTED: "Ws/SET_QUEUE_SELECTED",
    }),
    async setWsConnection(userTeamId, userAgentId) {
      this.$connect()
      window.addEventListener("webSocketOnOpen", this.$socket.onopen = (data) => this.createConnection(userTeamId))
      window.addEventListener("webSocketOnClose", this.$socket.onclose = (data) => this.closeConnection(data))
      window.addEventListener("webSocketOnError", this.$socket.onerror = (data) => this.errorWs(data))
      window.addEventListener("webSocketReconnect", this.$socket.reconnect = (data) => this.reconnectWs(data))
      window.addEventListener("webSocketReconnectError", this.$socket.reconnect_error = (data) => this.reconnectErrorWs(data))
      window.addEventListener("webSocketOnMessage", this.$options.sockets.onmessage = (data) => {
        if (data['data']) {
          const wsData = JSON.parse(data["data"])
          switch (wsData["action"]) {
            case "get_all_agents":
              this.SET_ALL_AGENTS({
                data: wsData["response"]
              })
              break
            case "get_all_tickets_in_queue":
              this.SET_QUEUE({
                data: wsData["response"]
              })
              break
            case "append_chat_to_agent":
              this.APPEND_CHAT_TO_AGENT({
                data: wsData["response"]
              })

              this.$socket.sendObj({
                db_name: process.env.VUE_APP_DATA_BASE,
                access_token: this.userToken,
                action: "get_ticket_chat_messages",
                body: {
                  ticket_id: wsData["response"].agent.chat.id
                }
              });
              break
            case "update_tickets_queue":
              this.UPDATE_TICKETS_QUEUE({
                data: wsData["response"]
              })
              break
            case "get_ticket_chat_messages":
              this.GET_TICKET_CHAT_MESSAGES({
                data: wsData["response"]
              })
              break
            case "agent_message":
              this.SET_AGENT_MESSAGE_SENDED({
                data: wsData["response"]
              })
              break
            case "update_ticket_chat_message":
              this.UPDATE_TICKET_CHAT_MESSAGE({
                data: wsData["response"]
              })

              if (!this.isSupervisor) this.goToLastMessage()

              break
            case "close_ticket":
              this.closeTicketId = wsData["response"].team.ticket.id
              this.CLOSE_TICKET({
                data: wsData["response"]
              })
              this.setAllAgents(userTeamId)
              this.setQueue(userTeamId)
              this.selectNextChat()
              break
            case "set_agent_status":
              this.SET_AGENT_STATUS(wsData['response']['agent']['status'])
              break
            case "change_agent_status":
              this.CHANGE_AGENT_STATUS({data: wsData['response']})
              break
            case "initial_agent_status":
              this.SET_AGENT_STATUS(wsData['response']['agent']['status'])
              break
            case "register_agent_in_team":
              this.SET_NEW_AGENT_IN_TEAM({data: wsData['response']})
              break
            case "logout_action":
              this.CHANGE_AGENT_STATUS({data: wsData['response']})
              break
            case "assign_service_to_agent":
              this.ASSIGN_SERVICE_TO_AGENT({data: wsData['response']})
              break
            case "update_ticket_state":
              this.UPDATE_TICKET_STATE({
                data: wsData["response"]
              })
              break
            case "availables_status":
              this.SET_AVAILABLES_STATUS_LIST({list: wsData['response']['status']})
              break
            case "get_supervise_chat_messages":
              this.SET_SUPERVISE_CHAT({
                ticketId: wsData.response.ticketId,
                messages: wsData.response.messages
              })
              break
            case "update_supervise_chat_message":
              this.UPDATE_SUPERVISE_CHAT_MESSAGE({
                ticketId: wsData.response.ticketId,
                message: wsData.response.message
              })

              if (this.isSupervisor) this.goToLastMessage()

              break
          }
        }
      })
    },
    async createConnection(userTeamId) {
      this.SET_WS_STATUS({
        status: true
      })

      if (this.$socket.readyState === 1) {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "register",
          body: {
            team_ids: this.teams
          }
        });

        this.setAllAgents(userTeamId)
        this.setQueue(userTeamId)
      }
    },
    getTicketChatMessages(chatId) {
      this.$socket.sendObj({
        db_name: process.env.VUE_APP_DATA_BASE,
        access_token: this.userToken,
        action: "get_ticket_chat_messages",
        body: {
          ticket_id: chatId,
        },
      });
    },
    selectNextChat() {
      const agentIndex = this.agents.findIndex(el => el.agent.id === this.userAgentId)

      if (agentIndex > -1) {
        const chat = this.agents[agentIndex].agent.chats.find(chat => chat.state === "open" && chat.id !== this.closeTicketId)

        if (Boolean(chat)) {
          this.getTicketChatMessages(chat.id)

          this.SET_QUEUE_SELECTED({ chatId: chat.id, userAgentId: this.userAgentId });
        }
      }
    },
    goToLastMessage() {
      const cardBodyElement = document.getElementById("compMessages")
      const chatHeight = Boolean(cardBodyElement) ? cardBodyElement.scrollTop : 0

      if (Boolean(cardBodyElement)) {
        if (chatHeight >= 0) {
          const lastChat = document.getElementById("lastChat")

          if (Boolean(lastChat)) {
            setTimeout(() => {
              cardBodyElement.scrollTo({
                top: lastChat.offsetHeight,
                behavior: 'smooth'
              })
            }, 300)
          }
        }
      }
    },
    closeConnection(data) {
      this.SET_WS_STATUS({
        status: false
      })
      if(this.loggedIn) {
        this.snackbarMessage = 'Conexão perdida, verifique sua internet.'
        this.snackbarColor = 'red'
        this.showSnackbar = true
        this.setWsIntervalReconnect()
      }
    },
    errorWs(data) {
    },
    reconnectWs() {
      this.SET_WS_STATUS({
        status: true
      })
    },
    reconnectErrorWs() {
      this.SET_WS_STATUS({
        status: false
      })
    },
    setWsIntervalReconnect() {
      if (this.$socket.readyState > 1) {
        this.wsInterval = setInterval(() => {
          if (this.$socket.readyState > 1) {
            this.$connect()
            this.setWsIntervalReconnect()
          }
        }, 10000);
      } else {
        clearInterval(this.wsInterval)
        this.setWsIntervalConnection()
      }
    },
    async setWsIntervalConnection() {
      if (this.$socket.readyState === 0) {
        this.wsIntervalConnection = setInterval(() => {
          this.setWsIntervalConnection()
        }, 20000)
      } else {
        clearInterval(this.wsIntervalConnection)
        if (!this.connecting) {
          this.connecting = true
          await this.createConnection()
          await this.setAllAgents(this.userTeamId)
          await this.setQueue(this.userTeamId)
          this.snackbarMessage = 'Conexão reestabelecida.'
          this.snackbarColor = 'green'
          this.showSnackbar = true
          this.connecting = false
        }
      }
    },
    async setAllAgents(userTeamId) {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_agents",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    async setQueue(userTeamId) {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_tickets_in_queue",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    async markReceived(data) {
      let dataObj = JSON.parse(data.data)
      switch (dataObj.action) {
        case 'message_sent_successfully':
          this.setMessageSucessfully({
            objMessage: {
              message_date: this.formatDateToScreen(dataObj.message_date),
              fe_message_id: dataObj.fe_message_id,
              chat_message_id: dataObj.chat_message_id,
              attachment_id: dataObj.attachment_id
            }
          })
          break;
        case 'send_message':
          let messageVisualized = false
          this.$socket.sendObj(
            {
              db_name: process.env.VUE_APP_DATA_BASE,
              user_id: localStorage.getItem("userId"),
              sender_user_id: dataObj.sender_user_id,
              chat_message_id: dataObj.chat_message_id,
              message_date: dataObj.message_date,
              action: 'set_message_received',
            }
          )
          if (this.activeChatObj && Number(dataObj.sender_user_id) === this.activeChatObj.id){
            messageVisualized = true
            this.addNewMessage({
              objMessage: {
                id: dataObj.chat_message_id,
                sender_user_id: dataObj.sender_user_id,
                receiver_user_id: [Number(localStorage.getItem("userId")), this.userName],
                message: dataObj.message,
                type: dataObj.type,
                attachment_id: dataObj.attachment_id,
                create_date: dataObj.message_date,
                received: true,
                visualized: messageVisualized,
                reply_message_id: dataObj.reply_message_id,
                reply_msg_user: this.messageReply ? this.messageReply.sender_user_id : null,
              },
            })
            this.confirmMessageView()
          }
          let lastUser = this.lastUsers.find(el => el.id === Number(dataObj.sender_user_id))
          if (!lastUser){
            lastUser = this.usersList.find(el => el.id === Number(dataObj.sender_user_id))
          }
          if (lastUser){
            this.setNewLastMessage({
              lastMessage: {
                id: dataObj.chat_message_id,
                sender_user_id: Number(dataObj.sender_user_id),
                receiver_user_id: Number(localStorage.getItem("userId")),
                message: dataObj.message,
                type: dataObj.type,
                create_date: dataObj.message_date,
                visualized: messageVisualized,
              },
              objUser: lastUser,
              isObjLastUser: true,
            })
          }

          this.newMsgScroll()
          break;
        case 'message_received_successfully':
          this.setMessageReceived({
            objMessage: {
              message_date: this.formatDateToScreen(dataObj.message_date),
              chat_message_id: dataObj.chat_message_id,
            }
          })
          break;
        case 'notify_message_view':
          if (this.activeChatObj && this.activeChatObj.id === Number(dataObj.sender_user_id)){
            this.setMessageView({
              userChat: Number(dataObj.sender_user_id)
            })
          }
          break;
        case 'error':
            alert("Não foi possível marcar mensagem como recebida: ", dataObj.message);
            break;
      }
      this.wsState = this.$socket.readyState
    },
  },
  computed: {
    ...mapGetters({
      loggedIn: "Login/getloggedIn",
      userToken: "Login/getToken",
      userId: 'Login/getUserId',
      userTeamId: "User/getUserTeamId",
      userAgentId: "User/getUserAgentId",
      queue: "Ws/getQueue",
      agents: "Ws/getAgents",
      teams: "User/getTeams",
      isSupervisor: "User/getIsSupervisor",
    })
  },
  async mounted() {
    if (this.loggedIn === true) {
      if(Boolean(this.userId)) {
        const agentData = await this.setUserTeam({
          token: this.userToken,
          userId: this.userId
        })
        this.dataAgentId = agentData.agentId
        if (Boolean(!this.$socket)) {
          await this.setWsConnection(agentData.teamId, agentData.agentId)
        }
      }
    }
  }
};
</script>
