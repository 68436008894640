import Swal from "sweetalert2";
import store from "@/store";
import router from "@/router";

const initialState = () => ({});
const state = initialState();
const getters = {};
const actions = {};
const mutations = {
  SET_MESSAGE_ERROR(state, { message, hideTecnicalSupportMessage=false }) {
    let errorMessage = ''
    let tecnicalSupportMessage = !hideTecnicalSupportMessage ? '<br><br>Em caso de dúvidas, entre em contato com o Suporte Técnico.' : ''
    switch (message) {
      case "invalid access_token":
        errorMessage =
          "O token de acesso parece estar expirado ou incorreto.\
          <br><br>\
          Sua sessão será encerrada.\
        ";
        store._actions["Login/UserLogOut"][0]();
        store._actions["User/reset"][0]();
        localStorage.clear();
        router.push("/");
        break;
      case "agent not found":
        errorMessage = "Agente não encontrado.\
        <br><br>\
        Verifique se este usuário está cadastrado como Agente.\
        ";
        break;
      case "channels not found":
        errorMessage =
          "Canais não encontrado.\
          <br><br>\
          Verifique se os canais estão relacionados as Api's  de comunicação.\
          <br><br>\
          ( Módulo: configuração whatsapp chat )\
        ";
        break;
      case "channel not found":
      errorMessage = "Canal não encontrado";
        break;
      case "whatsapp_channel not found":
        errorMessage = "Canal do Whatsapp não encontrado";
        break;
      default:
        if (message && message.toString().includes("Network Error")) {
          errorMessage =
            "Erro de conexão com a internet. <br><br>Verifique sua conexão.";
        } else {
          errorMessage = message;
        }
        break;
    }
    Swal.fire({
      icon: "error",
      title: "Oops...",
      html: errorMessage + tecnicalSupportMessage
    });
  },
};
export default {
  actions,
  state,
  getters,
  mutations,
  namespaced: true,
};
