<template>
  <v-layout pl-6 align-center style="attachmentLayout">
    <v-file-input
      v-show="false"
      v-model="attachment"
      prepend-icon="attach_file"
      multiple
      ref="fileInput"
    />
    <div>
      <v-layout>
        <div v-for="(item, index) in attachment" :key="index" class="px-1">
          <v-card height="55" width="55" elevation="2" color="blue">
            <v-img
              v-if="isImage(item)"
              :lazy-src="setSrcImage(item)"
              :src="setSrcImage(item)"
              style="hw100"
            />
            <div
              v-else
              class="d-flex justify-center d-flex align-center"
              style="h100"
            >
              <v-icon large color="white">
                {{ fileIcons(item) }}
              </v-icon>
            </div>
            <div style="btnBox">
              <v-btn
                x-small
                color="rgb(0, 0, 0, 0.5)"
                fab
                height="15px"
                width="15px"
                elevation="0"
                @click="removeAttachment(index)"
              >
                <v-icon x-small color="rgb(255, 255, 255, 0.8)"> close </v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AttachmentFile",

  props: {
    model: {
      type: String,
      required: false,
      default: "",
    },
  },

  data: () => {
    return {
      attachment: null,
    };
  },

  watch: {
    attachment: function () {
      if (this.attachment && this.attachment.length > 0) {
        this.$emit("hasAttachments");
      } else {
        this.$emit("noAttachments");
      }
    },
  },

  methods: {
    ...mapActions({
      postAttachment: "Attachment/postChatAttachment",
    }),

    ...mapGetters({
      getToken: "Login/getToken",
      getAttachmentApiState: "Attachment/getAttachmentApiState",
    }),

    isImage(item){
      (item.type.includes("image") ? true : false)
    },

    setSrcImage(item) {
      URL.createObjectURL(item)
    },

    fileIcons(item) {
      if (item.type.includes("pdf")) return "picture_as_pdf";
      else if (item.type.includes("text")) return "description";
      else return "attach_file";
    },

    openSelectFiles() {
      this.$refs.fileInput.$children[0].$el.click()
    },

    removeAttachment (index) {
      this.attachment.splice(index, 1)
    },

    loadingAttachment() {
      if (this.getAttachmentApiState() == 1) return true;
      else return false;
    },

    async postNewAttachment(attachment, socketDict) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      const anexa = async (file) => {
        let fileBase64 = await toBase64(file);
        fileBase64 = fileBase64.replace(/^data:.+;base64,/, "");

        const attachmentData = {
          name: file.name,
          datas_fname: file.name,
          type: file.type,
          filedatas: fileBase64,
          res_model: this.model,
          res_id: null,
        };
        let attachModel = await this.postAttachment({
          data: attachmentData,
          token: this.getToken(),
        });
        if (attachModel) {
          socketDict.attachment_id = attachModel.id;
          this.$socket.sendObj(socketDict);
        }
      };
      anexa(attachment);
    },

    cleanAttachmentList() {
      this.attachment = null;
    },
  },

  computed: {
    attachmentLayout() {
      return "overflow-x: scroll;max-width: 3000px;overflow-y: hidden";
    },

    hw100() {
      return "height:100%; width:100%";
    },

    h100() {
      return "height:100%";
    },

    btnBox() {
      return "margin-top: -53px; margin-left: 37px;";
    },
  },
};
</script>
