<template>
  <v-data-table
    no-results-text="Não há registro."
    no-data-text="Não há registro."
    loading-text="Carregando atendimentos..."
    :loading="loadingQueue"
    :items-per-page="itemsPerPage"
    hide-default-footer
    disable-sort
    :page.sync="page"
    :items="teamQueue"
    item-key="ticket"
    :headers="[
      {
        text: 'Ticket',
        value: 'ticket',
        sortable: false
      },
      {
        text: 'Status',
        value: 'state',
        sortable: false
      },
      {
        text: 'Solicitante',
        value: 'name',
        sortable: false
      },
      {
        text: 'Duração',
        value: 'service_time',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ]"
  >
    <template v-slot:[`item.state`]="{ item }">
      <td v-show="item">
        <v-chip
          small
        >
          {{ item.state === "draft" ? "Provisório" : item.state }}
        </v-chip>
      </td>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td v-show="item">
        {{ gFunc.mobileMask(item.name) }}
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <td class="text-end">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" :key="item.ticket">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <div
              v-for="(menu, i) in queueMenu"
              :key="i"
            >
              <v-list-item
                @click="setWsAction(menu.action, userTeamId, item.id)"
                link
              >
                <v-list-item-title>{{ menu.name }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="queueMenu.length > 1 && i < queueMenu.length -1" />
            </div>
          </v-list>
        </v-menu>
      </td>
    </template>
    <template v-slot:[`item.service_time`]="{ item, index }">
        <td v-show="item">
          {{ item.agent ? diffs[index] : ''}}
        </td>
      </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from "vuex";
import gFunc from "@/mixins/generic_functions";
import moment from "moment";
export default {
  name: "Queue",
  props: {
    page: {
      type: Number,
      default: 1
    },
    currentTeam: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
  },
  data: () => ({
    diffs: [],
    gFunc: gFunc,
    durationInterval: null
  }),
  methods: {
    async setWsAction(action, teamId, chatId) {
      switch (action) {
        case 1:
          await this.takeChat(teamId, chatId)
      }
    },
    async takeChat(teamId, chatId) {
      try {
        this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "new_agent_chat",
          body: {
            team_id: teamId,
            ticket_id: chatId,
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      }
    },
    getDuration(){
      let diffs = []
      this.teamQueue.forEach(element => {
        let newDate = new Date(element.duration);
        let formatedDate = new Date(
          newDate.valueOf() - newDate.getTimezoneOffset() * 60000
        );
        let diff = moment().diff(moment(formatedDate));
        let hours = Math.floor(diff / 3600000);  // Convert milissegundos para horas.
        diff -= hours * 3600000;
        let minutes = Math.floor(diff / 60000);  // Convert milissegundos restantes para minutos.
        diff -= minutes * 60000;
        let seconds = Math.floor(diff / 1000);

        let formattedDiff = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        diffs.push(formattedDiff)
      });
      this.diffs = diffs
    }
  },
  computed: {
    ...mapGetters({
      loadingQueue: "Management/getLoadingQueue",
      queue: "Ws/getQueue",
      queueMenu: "Management/getQueueMenu",
      banner: "Panel/getBanner",
      userTeamId: "User/getUserTeamId",
      userToken: "Login/getToken",
    }),
    teamQueue() {
      /*
        Propriedade computada que retorna fila de atendimentos do time.
        Apresentando apenas atendimentos em 'draft'.

        draft = Provisório
      */
      if (Boolean(this.queue[this.currentTeam] && this.queue[this.currentTeam].team.tickets.length > 0)) {
        return this.queue[this.currentTeam].team.tickets.filter(ticket => ticket.state === "draft")
      }
      else {
        return []
      }
    },
  },
  created() {
    this.durationInterval = setInterval(this.getDuration, 500);
  },
  beforeDestroy(){
    clearInterval(this.durationInterval)
  }
};
</script>