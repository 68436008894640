<template>
  <v-card rounded="xl" style="overflow: hidden">
    <v-layout
      :style="`
        height: calc(${cardHeight}vh - 125px); min-height: calc(${cardHeight}vh - ${diffHeight}px)`"
    >
      <v-card elevation="0" width="100%" rounded="md">
        <v-card-title
          class="pl-0 py-0 d-flex justify-space-between rounded-0"
          :style="`width: 100%;
                        height: 40px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        background-color: ${
                          Boolean(banner) ? banner['page_bg_color'] : 'white'
                        }
                  `"
        >
          <span
            class="pl-4 body-1"
            :style="`color: ${
              Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
            }`"
          >
            {{ title }}
          </span>
          <div>
            <v-menu offset-y rounded="lg" v-if="isSupervisor">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  plain
                  class="text-capitalize"
                  :color="
                    Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                  "
                >
                  <v-icon
                    class="mr-1"
                    :color="
                      Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                    "
                  >
                    filter_list
                  </v-icon>
                  {{ filterTitle }}
                </v-btn>
              </template>
              <v-list dense>
                <div v-for="(item, index) in filters" :key="index">
                  <v-list-item link class="py-0" @click="setTeam(item)">
                    <v-list-item-title class="text-caption">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-if="filters.length > 1 && index < filters.length - 1"
                  />
                </div>
              </v-list>
            </v-menu>
            <v-menu offset-y rounded="lg">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon v-if="hasOptions">
                  <v-icon
                    :color="
                      Boolean(banner) ? banner['page_text_color'] : '#1d1d1d'
                    "
                  >
                    more_vert
                  </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <div v-for="(item, index) in options" :key="index">
                  <v-list-item link class="py-0">
                    <v-list-item-icon>
                      <v-icon>
                        {{ item.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text-caption">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-if="options.length > 1 && index < options.length - 1"
                  />
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-card-title>
        <v-card-text class="pa-0" :style="`max-height: 48vh; overflow: auto;`">
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ApplicantCard",
  props: {
    title: "",
    cardTextClass: "",
    hasOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    filterTitle: {
      type: String,
      default: "",
    },
    cardHeight: {
      type: Number,
      defualt: 35,
    },
    diffHeight: {
      type: Number,
      default: 125,
    },
  },
  methods: {
    ...mapMutations({
      SET_CURRENT_QUEUE: "Ws/SET_CURRENT_QUEUE",
      SET_CURRENT_TEAM: "User/SET_CURRENT_TEAM",
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
    }),
    async setTeam(item) {
      this.SET_CURRENT_TEAM({teamId: item.id})
      this.SET_CURRENT_QUEUE({teamId: item.id})
      await this.setWsQueue()
    },
    async setWsQueue() {
      await this.setAllAgents()
      await this.setQueue()
    },
    async setAllAgents() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_agents",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    async setQueue() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_tickets_in_queue",
          body: {
            team_ids: this.teams.map(team => team.id)
          }
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      banner: "Panel/getBanner",
      teams: "User/getTeams",
      userToken: "Login/getToken",
      isSupervisor: "User/getIsSupervisor",
    }),
  },
};
</script>
