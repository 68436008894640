<template>
  <div>
    <v-data-table
      no-results-text="Não há registro."
      no-data-text="Não há registro."
      loading-text="Carregando atendimentos..."
      :loading="loadingQueue"
      :items-per-page="itemsPerPage"
      hide-default-footer
      disable-sort
      :page.sync="page"
      :items="supervisorQueue"
      item-key="create_date"
      :headers="[
        {
          text: 'Ticket',
          value: 'ticket',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'state',
          sortable: false,
        },
        {
          text: 'Solicitante',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Duração',
          value: 'service_time',
          sortable: false,
        },
        {
          text: 'Agente',
          value: 'agent',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
    >
      <template v-slot:[`item.state`]="{ item }">
        <td v-show="item">
          <v-chip small>
            {{ gFunc.queueStatus(item.state) }}
          </v-chip>
        </td>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <td v-show="item">
          {{ gFunc.mobileMask(item.name) }}
        </td>
      </template>
      <template v-slot:[`item.service_time`]="{ item, index }">
        <td v-show="item">
          {{ item.agent ? diffs[index] : "" }}
        </td>
      </template>
      <template v-slot:[`item.agent`]="{ item }">
        <td v-show="item">
          {{ item.agent.name ? item.agent.name.toString() : "" }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td class="text-end">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <div v-for="(menu, i) in supervisorMenu" :key="i">
                <v-list-item
                  @click="
                    setWsAction(menu.action, userTeamId, item.id, item.team.id, item)
                  "
                  link
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="
                    supervisorMenu.length > 1 && i < supervisorMenu.length - 1
                  "
                />
              </div>
            </v-list>
          </v-menu>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="showAgentDialog" width="350">
      <div style="padding: 15px; background-color: #ffffff;">
        <h3>Selecione um agente</h3>
      </div>

      <v-text-field
        solo
        v-model="search"
        label="Buscar por nome"
        prepend-inner-icon="mdi-magnify"
        style="background-color: #ffffff; border-radius: 0;"
      ></v-text-field>

      <v-data-table
        v-if="currentTicketTeamId"
        :headers="headers"
        :items="filteredAgents"
        :items-per-page="5"
        :style="{ borderRadius: '0' }"
      >
        <template v-slot:item.agent.name="{ item }">
          <span
          style="cursor: pointer;"
            @click="
              assignServiceToAgent(
                item.agent,
                currentTicketId,
                item.agent.team.id
              )
            "
          >
            {{ item.agent.name }}
          </span>
        </template>

        <template v-slot:item.agent.state.name="{ item }">
          <span
          :style="{cursor: 'pointer', color: item.agent.state.color}"
          @click="
              assignServiceToAgent(
                item.agent,
                currentTicketId,
                item.agent.team.id
              )">
            {{ item.agent.state.name }}
          </span>
        </template>
      </v-data-table>
    </v-dialog>
    <SuperviseChatDialog v-if="openSuperviseChatDialog"
      :open="openSuperviseChatDialog"
      :chatInfo="superviseChatInfo"
      @close="openSuperviseChatDialog = !openSuperviseChatDialog"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import SuperviseChatDialog from '@/components/SuperviseChat/MainComponent.vue'
import moment from "moment";
import gFunc from "@/mixins/generic_functions";
export default {
  name: "SupervisorQueue",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  components: {
    SuperviseChatDialog
  },
  data: () => ({
    showAgentDialog: false,
    currentTicketId: null,
    currentTicketTeamId: null,
    diffs: [],
    durationInterval: null,
    search: '',
    headers: [
        { text: 'Nome', value: 'agent.name' },
        { text: 'Status', value: 'agent.state.name' }
    ],
    openSuperviseChatDialog: false,
    superviseChatInfo: {},
    gFunc: gFunc
  }),
  methods: {
    ...mapMutations({
      SET_MESSAGE_ERROR: "Error/SET_MESSAGE_ERROR",
    }),
    async setWsAction(action, teamId, chatId, ticketTeamId, item) {
      switch (action) {
        case 0:
          await this.takeChat(teamId, chatId);
          break;
        case 1:
          this.selectAgent(chatId, ticketTeamId);
          break;
        case 2:
          this.superviseChatInfo = {
            ticket: {
              id: item.id,
              name: item.ticket,
              state: this.gFunc.queueStatus(item.state)
            },
            agentName: item.agent.name != false ? item.agent.name : 'N/A',
            teamName: item.team.name,
          }
          this.openSuperviseChatDialog = true
          break;
      }
    },
    async takeChat(teamId, chatId) {
      /*
        Método que assume o atendimento que esta na fila de espera.

        params:
          teamId ( INT ) = id do time que o agente pertence.
          chatId ( INT ) = id do atendimento.
      */
      try {
        this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "new_agent_chat",
          body: {
            team_id: teamId,
            ticket_id: chatId,
          },
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        store.commit("Error/SET_MESSAGE_ERROR", {
          message: messageError,
        });
      }
    },
    async assignServiceToAgent(agent, ticketId, teamId) {
      /*
        Método que transfere o atendimento para um agente.

        params:
          agentId  ( INT ) = id do agente que receberá o ticket.
          teamId   ( INT ) = id do time que o agente pertence.
          ticketId ( INT ) = id do atendimento.
      */

      try {
        let statusExists = this.availablesStatus.some(item => item.id === agent.state.id);
        if(statusExists){
          await this.$socket.sendObj({
            db_name: process.env.VUE_APP_DATA_BASE,
            access_token: this.userToken,
            action: "assign_service_to_agent",
            body: {
              agent_id: agent.id,
              ticket_id: ticketId,
              team_id: teamId,
            },
          });
  
          this.currentTicketId = null;
          this.currentTicketTeamId = null;
          this.showAgentDialog = false;
          await this.setAllAgents();
          await this.setQueue();
        }
        else {
          const messageError = 'O agente selecionado não pode receber atendimentos no momento!';
          this.SET_MESSAGE_ERROR({
            message: messageError,
            hideTecnicalSupportMessage: true
          });
        }
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    selectAgent(ticketId, ticketTeamId) {
      this.currentTicketId = ticketId;
      this.currentTicketTeamId = ticketTeamId;
      this.showAgentDialog = true;
    },
    async setAllAgents() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_agents",
          body: {
            team_ids: this.teams.map((team) => team.id),
          },
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    async setQueue() {
      try {
        await this.$socket.sendObj({
          db_name: process.env.VUE_APP_DATA_BASE,
          access_token: this.userToken,
          action: "get_all_tickets_in_queue",
          body: {
            team_ids: this.teams.map((team) => team.id),
          },
        });
      } catch (e) {
        const messageError = e.response ? e.response.data.message : e;
        this.SET_MESSAGE_ERROR({
          message: messageError,
        });
      }
    },
    getDuration() {
      let diffs = [];
      this.supervisorQueue.forEach((element) => {
        let newDate = new Date(element.duration);
        let formatedDate = new Date(
          newDate.valueOf() - newDate.getTimezoneOffset() * 60000
        );
        let diff = moment().diff(moment(formatedDate));
        let hours = Math.floor(diff / 3600000);
        diff -= hours * 3600000;
        let minutes = Math.floor(diff / 60000);
        diff -= minutes * 60000;
        let seconds = Math.floor(diff / 1000);

        let formattedDiff = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        diffs.push(formattedDiff);
      });
      this.diffs = diffs;
    },
  },
  computed: {
    ...mapGetters({
      loadingQueue: "Management/getLoadingQueue",
      queue: "Ws/getQueue",
      queueMenu: "Management/getQueueMenu",
      supervisorMenu: "Management/getSupervisorMenu",
      currentQueue: "Ws/getCurrentQueue",
      banner: "Panel/getBanner",
      userTeamId: "User/getUserTeamId",
      userToken: "Login/getToken",
      agents: "Ws/getAgents",
      teams: "User/getTeams",
      availablesStatus: "User/getAvailablesStatus"
    }),
    supervisorQueue() {
      /*
        Propriedade computada que retorna fila de atendimentos do supervisor.
        Apresentando apenas atendimentos em 'draft'.

        draft = Provisório
      */
      if (
        Boolean(
          this.queue[this.currentQueue] &&
            this.queue[this.currentQueue].team.tickets.length > 0
        )
      ) {
        return this.queue[this.currentQueue].team.tickets.filter(
          (ticket) => ticket.state != "close"
        );
      } else {
        return [];
      }
    },
    filteredAgents() {
      if (!this.currentTicketTeamId) return [];

      let filtered = this.agents.filter(
        (agent) => agent.agent.team.id == this.currentTicketTeamId
      );

      if (this.search) {
        filtered = filtered.filter((agent) =>
          agent.agent.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return filtered;
    },
  },
  created() {
    this.durationInterval = setInterval(this.getDuration, 500);
  },
  beforeDestroy() {
    clearInterval(this.durationInterval);
  },
};
</script>