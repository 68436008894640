var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"no-results-text":"Não há registro.","no-data-text":"Não há registro.","loading-text":"Carregando atendimentos...","loading":_vm.loadingQueue,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","disable-sort":"","page":_vm.page,"items":_vm.teamQueue,"item-key":"ticket","headers":[
    {
      text: 'Ticket',
      value: 'ticket',
      sortable: false
    },
    {
      text: 'Status',
      value: 'state',
      sortable: false
    },
    {
      text: 'Solicitante',
      value: 'name',
      sortable: false
    },
    {
      text: 'Duração',
      value: 'service_time',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    },
  ]},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:`item.state`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.state === "draft" ? "Provisório" : item.state)+" ")])],1)]}},{key:`item.name`,fn:function({ item }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(_vm.gFunc.mobileMask(item.name))+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('td',{staticClass:"text-end"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({key:item.ticket,attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.queueMenu),function(menu,i){return _c('div',{key:i},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setWsAction(menu.action, _vm.userTeamId, item.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(menu.name))])],1),(_vm.queueMenu.length > 1 && i < _vm.queueMenu.length -1)?_c('v-divider'):_vm._e()],1)}),0)],1)],1)]}},{key:`item.service_time`,fn:function({ item, index }){return [_c('td',{directives:[{name:"show",rawName:"v-show",value:(item),expression:"item"}]},[_vm._v(" "+_vm._s(item.agent ? _vm.diffs[index] : '')+" ")])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }